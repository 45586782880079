import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, effect, inject } from '@angular/core';
import { AuthenticationService } from '@ev-portals/cp/frontend/shared/auth/util';
import { FeedbackMessageService } from '@ev-portals/cp/frontend/shared/util';
import {
  AlertDialogComponent,
  ConfirmDialogComponent,
  NotificationComponent,
  PromptDialogComponent,
} from '@ev-portals/ev/frontend/ui-library';
import { DialogService } from '@ev-portals/ev/frontend/util';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
  standalone: true,
  selector: 'cp-dynamic-content',
  imports: [
    CommonModule,
    PromptDialogComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    NotificationComponent,
    ToastModule,
  ],
  providers: [MessageService],
  templateUrl: './dynamic-content.component.html',
  styleUrls: ['./dynamic-content.component.scss'],
})
export class DynamicContentComponent {
  authenticationService = inject(AuthenticationService);
  feedbackMessageService = inject(FeedbackMessageService);
  dialogService = inject(DialogService);
  messageService = inject(MessageService);
  changeDetectorRef = inject(ChangeDetectorRef);
  customRenderer = 0;

  constructor() {
    this.#listenToImpersonationMode();
    this.#listenToInternalUser();
    this.#listenToLogoutSuccess();

    effect(() => {
      const message = this.feedbackMessageService.$alertMessage();
      if (message) {
        this.messageService.add({
          id: message.id,
          severity: message.type,
          life: message.displaySeconds ? message.displaySeconds * 1000 : 5000,
          detail: message.message,
          contentStyleClass: 'flex flex-row items-center',
        });
      }
    });
  }

  #listenToInternalUser(): void {
    this.authenticationService.user$.subscribe(user => {
      user?.isInternalUser
        ? this.feedbackMessageService.addInternalUserNotification()
        : this.feedbackMessageService.removeInternalUserNotification();
    });
  }

  #listenToImpersonationMode(): void {
    effect(
      () => {
        this.authenticationService.$impersonationMode()
          ? this.feedbackMessageService.addImpersonationNotification()
          : this.feedbackMessageService.removeImpersonationNotification();
      },
      { allowSignalWrites: true },
    );
  }

  #listenToLogoutSuccess(): void {
    this.authenticationService.logoutSuccess$.subscribe({
      next: () => {
        this.feedbackMessageService.showSuccessMessage(
          $localize`You have been successfully logged out.`,
        );
      },
      error: () => {
        this.feedbackMessageService.showErrorMessage();
      },
    });
  }
}
