@for (
  notification of feedbackMessageService.$notifications();
  track notification
) {
  <ev-notification
    class="mb-2"
    [type]="notification.type"
    [title]="notification.title"
    [closable]="notification.closable"
    (closeNotification)="
      feedbackMessageService.removeNotificationById(notification.id)
    "
  >
    <div class="message" [innerHTML]="notification.message"></div>
  </ev-notification>
}

<!-- Alert Message - controlled by the FeedbackMessageService -->
<p-toast
  position="top-center"
  (onClose)="feedbackMessageService.clearAlertMessage()"
  styleClass="!opacity-100"
/>

<!-- Modal Dialogs - controlled by the DialogService -->
@if (dialogService.promptDialogData(); as promptDialogData) {
  <ev-prompt-dialog
    [title]="promptDialogData.title"
    [subtitle]="promptDialogData.subtitle"
    [label]="promptDialogData.label"
    [placeholder]="promptDialogData.placeholder"
    [submitButtonText]="promptDialogData.submitButtonText"
    (closeDialog)="dialogService.onPromptResult(null)"
    (submitDialog)="dialogService.onPromptResult($event)"
  ></ev-prompt-dialog>
}

@if (dialogService.confirmDialogData(); as confirmDialogData) {
  <ev-confirm-dialog
    [title]="confirmDialogData.title"
    [subtitle]="confirmDialogData.subtitle"
    (confirmDialog)="dialogService.onConfirmResult(true)"
    (closeDialog)="dialogService.onConfirmResult(false)"
  ></ev-confirm-dialog>
}

@if (dialogService.alertDialogData(); as alertDialogData) {
  <ev-alert-dialog
    [title]="alertDialogData.title"
    [subtitle]="alertDialogData.subtitle"
    (closeDialog)="dialogService.onAlertResult(true)"
  >
  </ev-alert-dialog>
}
