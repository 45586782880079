import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AngularModule } from '@atoms/angular';
import { AccountAddressItem, SelectedLocationDto } from '@ev-portals/cp/frontend/shared/api-client';
import { AddressType, SelectedLocationService } from '@ev-portals/cp/frontend/shared/auth/util';
import { notNullOrUndefined } from '@ev-portals/ev/frontend/util';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { map, Observable } from 'rxjs';

import { LocationItemComponent } from '../location-item';

@Component({
  standalone: true,
  selector: 'cp-current-locations',
  templateUrl: './current-locations.component.html',
  styleUrls: ['./current-locations.component.scss'],
  imports: [CommonModule, AngularModule, LocationItemComponent, ProgressSpinnerModule],
})
export class CurrentLocationsComponent {
  #selectedLocationService = inject(SelectedLocationService);

  public userAddressesWithType$: Observable<AddressWithType[]> =
    this.#selectedLocationService.selectedLocation$.pipe(
      notNullOrUndefined(),
      map(selectedLocation => {
        return [
          {
            addressType: AddressType.SoldTo,
            addressInfo: this.#findAddressByKey(AddressType.SoldTo, selectedLocation),
          },
          {
            addressType: AddressType.ShipTo,
            addressInfo: this.#findAddressByKey(AddressType.ShipTo, selectedLocation),
          },
          {
            addressType: AddressType.BillTo,
            addressInfo: this.#findAddressByKey(AddressType.BillTo, selectedLocation),
          },
          {
            addressType: AddressType.Payer,
            addressInfo: this.#findAddressByKey(AddressType.Payer, selectedLocation),
          },
        ];
      }),
    );

  #findAddressByKey(
    addressType: AddressType,
    selectedLocation: SelectedLocationDto,
  ): AccountAddressItem {
    const addressKey = selectedLocation[addressType];
    const address = selectedLocation.includedAddresses.find(
      addressItem => addressItem.key === addressKey,
    );

    if (!address) throw new Error('Address not found ');

    return address;
  }
}

interface AddressWithType {
  addressType: AddressType;
  addressInfo: AccountAddressItem;
}
